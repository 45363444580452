<template>
	<div class="flex flex-col justify-center items-center">
		<div class="h-56"></div>
		<div class="w-full flex">
			<div class="wp-30"></div>
			<div class="wp-40 flex justify-center items-center">
				<img src="@/assets/images/logo_big.png" alt="ZEROQUEST" />
			</div>
			<div class="wp-20"></div>
		</div>
		<div class="h-20"></div>

		<div class="flex flex-col justify-center items-center">
			<div v-if="platform === 'iOS'">
				<div>
					<a @click="loginWithKakao">
						<img
							class="mb-4"
							src="@/assets/images/kakaoo.png"
							alt="카카오 로그인 버튼"
						/>
					</a>
				</div>
				<div>
					<a @click="loginWithApple">
						<img
							class="mb-10"
							src="@/assets/images/applee.png"
							alt="애플 로그인 버튼"
						/>
					</a>
				</div>
			</div>
			<div v-else-if="platform === 'Mac OS X'">
				<div>
					<a @click="loginWithKakao">
						<img
							class="mb-4"
							src="@/assets/images/kakaoo.png"
							alt="카카오 로그인 버튼"
						/>
					</a>
				</div>
				<div>
					<a @click="loginWithApple">
						<img
							class="mb-10"
							src="@/assets/images/applee.png"
							alt="애플 로그인 버튼"
						/>
					</a>
				</div>
			</div>

			<div v-else-if="platform === 'Android'">
				<div>
					<a @click="loginWithKakao">
						<img
							class="mb-4"
							src="@/assets/images/kakaoo.png"
							alt="카카오 로그인 버튼"
						/>
					</a>
				</div>
			</div>

			<div v-else-if="platform === 'Windows NT'">
				<div>
					<a @click="loginWithKakao">
						<img
							class="mb-4"
							src="@/assets/images/kakaoo.png"
							alt="카카오 로그인 버튼"
						/>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="break-words text-black media-Bottom">
		<div class="font-semibold text-2xl">
			제로퀘스트와 함께 지구를 살려요!🌍🎮
		</div>
		<br />
		<div class="font-medium text-lg">
			탄소 발자국을 줄이는 행동, 그게 바로 우리의 게임 미션!<br />
			성공할 때마다 ESG 포인트도 적립! <br />
			포인트는 My point에서 카카오페이로 출금하면 재태크도 됩니다.<br />

			계단 오르기부터 자전거 타기까지!<br />
			제로퀘스트와 함께하면, 지구를 살리면서 주머니도 가득!<br />
			함께 지구를 더 즐겁게, 건강하게 만들어가요!
		</div>
		<br />
		<div class="pt-4 font-semibold text-xl">
			지금 바로 시작! <br />
			제로퀘스트와 지구의 히어로가 되어보세요!🌟🚀<br />
			쉿! 소문내기까지 하면 추가 보너스도 빵빵합니다.
		</div>
	</div>
</template>

<script lang="ts" setup>
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import openSSLCrypto from '@/utils/openSSLCrypto';
import { onMounted, ref } from 'vue';

const showMode = ref(router.currentRoute.value.query.showMode);
const isLogin = ref(router.currentRoute.value.query.isLogin);

const platform = ref('');

onMounted(() => {
	if (store.state.showMode === 'webview' || showMode.value === 'webview') {
		store.state.showMode = 'webview';

		if (isLogin.value == 1) {
			store.state.isLoading = true;
			window.Java.jsLogin();
		}
	}

	detectDevice();
});

const detectDevice = () => {
	// 기기 구분 코드
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	console.log('Current User Agent: ' + userAgent);

	// iOS 검사 (iPhone, iPad, iPod)
	if (/iPhone|iPad|iPod/.test(userAgent)) {
		console.log('iOS');
		platform.value = 'iOS';
		localStorage.setItem('platform', 'iOS');
	}

	// Mac OS X 검사
	else if (/Mac OS X/.test(userAgent)) {
		console.log('Mac os');
		platform.value = 'Mac OS X';
		localStorage.setItem('platform', 'Mac OS X');
	}
	// Android 검사
	else if (/Android/.test(userAgent)) {
		console.log('Android');
		platform.value = 'Android';
		localStorage.setItem('platform', 'Android');
	}

	// Window NT 검사
	else if (/Windows NT/.test(userAgent)) {
		console.log('Windows NT');
		platform.value = 'Windows NT';
		localStorage.setItem('platform', 'Windows NT');
	}
};

const loginWithApple = () => {
	window.flutter_inappwebview
		.callHandler('handleAppleLoginBtn')
		.then((res: any) => {
			if (res) {
				const email = res.email;
				const name = res.familyName + res.givenName;
				const id = res.userIdentifier;
				const fcmToken = res.fcmToken;
				login(email, name, id, 'apple', fcmToken);
			} else {
				console.error('Invalid user information received:', res);
			}
		})
		.catch((error: any) => {
			console.error('Error fetching user info:', error);
		});
	// login(
	//   "pkyh1@naver.com",
	//   "김용현",
	//   "001109.fa8d7d012ebb4b3fa5e489258f414cc7.0508",
	//   "apple"
	// );
};

const loginWithKakao = () => {
	console.log('loginWithKakao');
	// if (platform.value === 'Android' ) {
	if (platform.value === 'Android' || platform.value === 'iOS') {
	// if (platform.value === 'Android' || platform.value === 'Mac OS X') {
		window.flutter_inappwebview
			.callHandler('handleKakaoLoginBtn')
			.then((res: any) => {
				if (res) {
					const email = res.kakao_account.email;
					const name = res.kakao_account.profile.nickname;
					const id = res.id;
					const fcmToken = res.fcm_token;

					login(email, name, id, 'kakao', fcmToken);
				} else {
					console.error('Invalid user information received:', res);
				}
			})
			.catch((error: any) => {
				console.error('Error fetching user info:', error);
			});
	} else {
		window.Kakao.Auth.login({
			scope: 'profile_nickname, profile_image, account_email',
			success: getProfile,
		});
	}
};

const getProfile = (authObj: any) => {
	const access_token = authObj.access_token;

	axios({
		method: 'GET',
		url: 'https://kapi.kakao.com/v2/user/me',
		headers: {
			Authorization: `Bearer ${access_token}`,
		},
	})
		.then(userInfo => {
			if (
				userInfo.data &&
				userInfo.data.kakao_account &&
				userInfo.data.kakao_account.profile
			) {
				const email = userInfo.data.kakao_account.email;
				const name = userInfo.data.kakao_account.profile.nickname;
				const id = userInfo.data.id;

				login(email, name, id, 'kakao');
			} else {
				console.error('Invalid user information received:', userInfo);
			}
		})
		.catch(error => {
			console.error('Error fetching user info:', error);
		});
};

const login = async (
	email: any,
	name: any,
	id: any,
	social: string,
	fcmToken: any,
) => {
	store.commit('auth/setInitToken');
	store.state.isBalanceUpdate = true;

	try {
		const loginToken = {
			id,
			email,
			name,
			timestamp: Date.now(),
			social,
			os: platform.value,
		};

		store.commit('auth/setUserName', {
			userName: name,
		});
		store.commit('auth/setUserEmail', {
			userEmail: email,
		});
		store.commit('auth/setUserAccount', {
			userAccount: id,
		});

		const json = JSON.stringify(loginToken);

		const encode = openSSLCrypto.encode(json);

		store.dispatch('auth/socialLogin', {
			token: encode,
			fcmToken,
		});
	} catch (error) {
		console.error('Login failed', error);
	}
};
</script>

<style lang="scss">
.google-btn {
	width: 80%;
	height: 80px;
	border: 2px solid #dadada;
	font-weight: 500;
	font-size: 25px;
	line-height: 1;
	color: #000;
	text-align: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 99px;

	img {
		width: 12px;
	}
}
.google-btn strong {
	padding: 0 8px 0 30px;
	font-weight: 600;
	font-size: 34px;
}
.google-btn img {
	width: 52px;
}
.w-310px {
	width: 310px;
}
.h-30px {
	height: 30px;
}
</style>
