import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import '@/input.css';
import '@/styles/layout.css';
import '@/output.css';
import '@/ecoplanet.css';
import 'element-plus/dist/index.css';
// import VueDaumPostcode from 'vue-daum-postcode';

import globalComponents from '@/components';

const app = createApp(App)
	.use(store)
	.use(router)
	.use(globalComponents)
	.use(ElementPlus);
// .use(VueDaumPostcode)

// kakao.d.ts
declare global {
	interface Window {
		Kakao: any;
		naver: any;
	}
}

router.isReady().then(() => {
	app.mount('#app');
});

window.Kakao.init('536b96a4cb55add4fe68874a9bcdc931');
